import React from 'react';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import Layout from '../../components/layout';
import { Typography, Box, List, ListItem, Link as LinkUi } from '@material-ui/core';
import { Link } from 'gatsby';

const TermsAndConditionsPage = (props, { children }) => {
  return (
    <Layout>
      <SectionContainerLayoutWithFilter title='TERMS AND CONDITIONS OF USE'>
        <>
          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Introduction</Box>
          </Typography>
          <Typography gutterBottom variant='body2' color='textPrimary'>
            These terms and conditions apply between you, the User of this Website (including any sub-domains, unless
            expressly excluded by their own terms and conditions), and Global Manufacturing & Industrialisation Summit,
            the owner and operator of this Website. Please read these terms and conditions carefully, as they affect
            your legal rights. Your agreement to comply with and be bound by these terms and conditions is deemed to
            occur upon your first use of the Website. If you do not agree to be bound by these terms and conditions, you
            should stop using the Website immediately.
          </Typography>
          <Typography gutterBottom variant='body2' color='textPrimary'>
            You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms
            and conditions, you represent and warrant that you are at least 18 years of age.
          </Typography>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Definitions:</Box>
          </Typography>
          <List>
            <ListItem>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Content:
                  </Typography>
                </Box>
                Any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and
                software and any other form of information capable of being stored in a computer that appears on or
                forms part of this Website, including any such content uploaded by Users.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Global Manufacturing & Industrialisation Summit, Global Manufacturing Organisation Limited, we or
                    us:
                  </Typography>
                </Box>
                Global Manufacturing Organisation Limited (GMOL), a company incorporated in the United Arab Emirates
                with registered number MC11712 whose registered office is at Office FD, Ground Floor, Accelerator
                Building, Masdar City, Abu Dhabi, United Arab Emirates. You can contact Global Manufacturing
                Organisation Limited by email at admin@gmisummit.com.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    User or you:
                  </Typography>
                </Box>
                Any third party that accesses the Website and is not either (i) employed by Global Manufacturing &
                Industrialisation Summit and acting in the course of their employment or (ii) engaged as a consultant or
                otherwise providing services to Global Manufacturing & Industrialisation Summit and accessing the
                Website in connection with the provision of such services.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Website:
                  </Typography>
                </Box>
                The website that you are currently using, https://www.gmisummit.com/, and any sub-domains of this site
                unless expressly excluded by their own terms and conditions.
              </Typography>
            </ListItem>
          </List>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Intellectual Property and Acceptable Use</Box>
          </Typography>
          <ol>
            <li>
              <Box mb={2}>
                <Typography gutterBottom variant='body1'>
                  All Content included on the Website, unless uploaded by Users, is the property of Global Manufacturing
                  Organisation Limited, our affiliates or other relevant third parties. By continuing to use the Website
                  you acknowledge that such Content is protected by copyright, trademarks, database rights and other
                  intellectual property rights. Nothing on this Website shall be construed as granting, by implication,
                  estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on
                  the Website without the owner’s prior written permission.
                </Typography>
              </Box>
            </li>
            <li>
              <Box mb={2}>
                <Typography gutterBottom variant='body1'>
                  You may, for your own personal, non-commercial use only, do the following:
                </Typography>
              </Box>
              <ol type='a'>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    Retrieve, display and view the Content on a computer screen
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any
                    Content without the written permission of Global Manufacturing Organisation Limited.
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    You may link to our homepage, provided you do so in a way that is fair and legal and does not damage
                    our reputation or take advantage of it.
                  </Typography>
                </li>
              </ol>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Prohibited Use</Box>
          </Typography>
          <Typography gutterBottom variant='body2' color='textPrimary'>
            You may not use the Website for any of the following purposes:
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                In any way which causes, or may cause, damage to the Website or interferes with any other person’s use
                or enjoyment of the Website;
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                In any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise
                objectionable or in breach of any applicable law, regulation, governmental order;
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                For making, transmitting or storing electronic copies of Content protected by copyright without the
                permission of the owner.
              </Typography>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Registration</Box>
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                You must ensure that the details provided by you on registration or at any time are correct and
                complete.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                You must inform us immediately of any changes to the information that you provide when registering by
                updating your personal details to ensure we can communicate with you effectively.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you
                breach these terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                You may cancel your registration at any time by informing us in writing to the address at the end of
                these terms and conditions. If you do so, you must immediately stop using the Website. Cancellation or
                suspension of your registration does not affect any statutory rights.
              </Typography>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Links to Other Websites</Box>
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                This Website may contain links to other sites. Unless expressly stated, these sites are not under the
                control of Global Manufacturing & Industrialisation Summit or that of our affiliates.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                We assume no responsibility for the content of such Websites and disclaim liability for any and all
                forms of loss or damage arising out of the use of them.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                The inclusion of a link to another site on this Website does not imply any endorsement of the sites
                themselves or of those in control of them.
              </Typography>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Privacy Policy</Box>
          </Typography>
          <Typography gutterBottom variant='body2' color='textPrimary'>
            Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated into
            these terms and conditions by reference. To view the Privacy Policy, please click{' '}
            <Link
              className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'
              to='/privacy-policy'>
              here
            </Link>
            .
          </Typography>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Availability of the Website and Disclaimers</Box>
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                Any online facilities, tools, services or information that Global Manufacturing & Industrialisation
                Summit makes available through the Website (the Service) is provided “as is” and on an “as available”
                basis. We give no warranty that the Service will be free of defects and/or faults. To the maximum extent
                permitted by law, we provide no warranties (express or implied) of fitness for a particular purpose,
                accuracy of information, compatibility and satisfactory quality. Global Manufacturing &
                Industrialisation Summit is under no obligation to update information on the Website.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Whilst Global Manufacturing & Industrialisation Summit uses reasonable endeavours to ensure that the
                Website is secure and free of errors, viruses and other malware, we give no warranty or guaranty in that
                regard and all Users take responsibility for their own security, that of their personal details and
                their computers.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Global Manufacturing & Industrialisation Summit accepts no liability for any disruption or
                non-availability of the Website.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Global Manufacturing & Industrialisation Summit reserves the right to alter, suspend or discontinue any
                part (or the whole of) the Website including, but not limited to, any products and/or services
                available. These terms and conditions shall continue to apply to any modified version of the Website
                unless it is expressly stated otherwise.
              </Typography>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>Limitation of liability</Box>
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or
                personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your
                liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your
                liabilities in any way that is not permitted under applicable law.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                We will not be liable to you in respect of any losses arising out of events beyond our reasonable
                control.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                To the maximum extent permitted by law, Global Manufacturing & Industrialisation Summit accepts no
                liability for any of the following:
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts,
                goodwill or commercial opportunities;
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Loss or corruption of any data, database or software;
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Any special, indirect or consequential loss or damage.
              </Typography>
            </li>
          </ol>

          <Typography gutterBottom variant='h6' className='text-uppercase'>
            <Box my={3}>General</Box>
          </Typography>
          <ol>
            <li>
              <Typography gutterBottom variant='body1'>
                You may not transfer any of your rights under these terms and conditions to any other person. We may
                transfer our rights under these terms and conditions where we reasonably believe your rights will not be
                affected.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                These terms and conditions may be varied by us from time to time. Such revised terms will apply to the
                Website from the date of publication. Users should check the terms and conditions regularly to ensure
                familiarity with the then current version.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                These terms and conditions together with the Privacy Policy and Cookies Policy contain the whole
                agreement between the parties relating to its subject matter and supersede all prior discussions,
                arrangements or agreements that might have taken place in relation to the terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and conditions and no
                third party will have any right to enforce or rely on any provision of these terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                If any court or competent authority finds that any provision of these terms and conditions (or part of
                any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the
                extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of
                these terms and conditions will not be affected.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be
                deemed a waiver of that, or any other, right or remedy.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom variant='body1'>
                This Agreement shall be governed by and interpreted according to the law of England and Wales and all
                disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to
                the exclusive jurisdiction of the English courts.
              </Typography>
            </li>
          </ol>
          <Typography gutterBottom variant='body2' color='textPrimary'>
            You can contact Global Manufacturing & Industrialisation Summit by email on{' '}
            <LinkUi href='mailto:admin@gmisummit.com'>admin@gmisummit.com</LinkUi>.
          </Typography>
        </>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default TermsAndConditionsPage;
